import React, { useLayoutEffect } from "react"
import ReactGA from "react-ga";

import SEO from "../components/seo"

import Footer from "../components/footer"

import Modali, { useModali } from "../components/modal"
import Top from "../components/signup/top"
import Main from "../components/signup/main"
import Hero from "../components/partner/hero"
import Partners from "../components/video/partners"
import Why from "../components/partner/why"
import "../scss/style.scss"
import Value from "../components/partner/value"
import Number from "../components/partner/number"
import Fans from "../components/new-landing/fans"
import TrustCompany from "../components/partner/trustCompany"
import Tools from "../components/partner/tools"
import Start from "../components/partner/start"
import Join from "../components/partner/join"
import Team from "../components/partner/team"
import seoImage from "../images/partner/bg_mobile.png"
import trustImage from "../images/trust-img.png"
import Layout from "../components/layout"
import NewFooter from "../components/pricing/footer"

export default function Partner() {
  const [videoModal, toggleVideoModal] = useModali()
    const navItemClick = (navItem) => {
        ReactGA.event({
            category: 'NAV_ITEM',
            action: `Clicked ${navItem} Nav Item of Partner Page`
        });
    }
  return (
    <div className="h-full font-robo partner-landing">
      <SEO title="Join the LUCA Plus Partner Program today" einvoice={true} description="Join the 40+ Accountants/Bookkeepers who have already joined the LUCA+ Partner Program. Attract new clients & access a new income stream." image={seoImage}
      keywords="partner" />
      <Top partner={true} toggleVideoModal={toggleVideoModal} navItemClick={navItemClick}/>
      <Hero page="Partner Page" />
      <Partners page="Partner Page" />
      <Value />
      <Why toggleVideoModal={toggleVideoModal} />
      <Number />
      <TrustCompany />
      {/*<Fans />*/}
      {/*<Tools />*/}
      {/* <Start /> */}
      <Join />
      <Team />
      <NewFooter page='Partner Page' simple={true}/>
      <Modali.Modal {...videoModal}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/L8Q7ScsjLtQ?autoplay=1&enable_js=1"
            title="Introduction to LUCA+"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </Modali.Modal>
    </div>
  )
}
